import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

import { login } from "../api";
import { useAuth } from "../context/AuthContext";

const Login = () => {
  const navigate = useNavigate();
  const { login: setAuth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
  });

  //************ INPUT CHANGE *********** */

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setFormErrors({
      email: "",
      password: "",
    });
  };

  //************ SUBMIT FORM *********** */

  const handleSubmit = async (event) => {
    event.preventDefault();

    let hasError = false;

    const newErrors = {
      email: "",
      password: "",
    };

    const { email, password } = formData;

    if (!email) {
      newErrors.email = "Email is required.";
      hasError = true;
    }

    if (!password) {
      newErrors.password = "Password is required.";
      hasError = true;
    } else if (password.length < 6) {
      newErrors.password = "Password must be at least 6 characters.";
      hasError = true;
    }

    setFormErrors(newErrors);

    if (!hasError) {
      setLoading(true);

      try {
        const data = await login(formData);
        setAuth(data.token);
        navigate("/");
      } catch (error) {
        toast.error("Invalid credentials!");
        console.error("Login error:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="flex items-center justify-center h-screen px-5 login-background">
      <div
        className="flex flex-col items-center justify-center gap-5 max-w-2xl
        bg-transparent py-10 md:px-10 px-4 rounded-md w-full border border-primary"
      >
        <h1 className="uppercase font-bold text-6xl text-center">Login</h1>

        <ToastContainer autoClose={2000} position="top-right" />

        <form onSubmit={handleSubmit} className="flex flex-col gap-4 w-full">
          <div className="gap-1">
            <input
              id="email"
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              className={`border xl:h-12 h-10 px-3 rounded w-full bg-light
              ${formErrors.email ? "border-pink-700" : "border-primary"}`}
            />
            {formErrors.email && (
              <div className="text-pink-700 text-sm">{formErrors.email}</div>
            )}
          </div>

          <div className="gap-1">
            <div className="relative flex items-center">
              <input
                id="password"
                name="password"
                placeholder="Password"
                onChange={handleChange}
                value={formData.password}
                type={showPassword ? "text" : "password"}
                className={`border xl:h-12 h-10 px-3 rounded w-full pr-12 bg-light 
                ${formErrors.password ? "border-pink-700" : "border-primary"}`}
              />
              <button
                type="button"
                className="absolute right-3"
                onClick={() => setShowPassword((prev) => !prev)}
              >
                {showPassword ? (
                  <FaRegEye size={23} className="text-secondary" />
                ) : (
                  <FaRegEyeSlash size={23} className="text-secondary" />
                )}
              </button>
            </div>
            {formErrors.password && (
              <div className="text-pink-700 text-sm">{formErrors.password}</div>
            )}
          </div>

          <div className="flex justify-between items-center">
            {loading ? (
              <div
                className="flex gap-2 p-3 border border-neutral rounded w-fit bg-secondary 
                items-center text-white font-semibold"
              >
                <div className="animate-spin rounded-full border-2 border-t-2 border-t-white border-transparent h-5 w-5"></div>
                <span>Signing in...</span>
              </div>
            ) : (
              <button className="w-fit bg-primary font-medium text-white uppercase rounded px-4 py-3">
                Sign In
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
