import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Alert, Skeleton, Snackbar } from "@mui/material";
import { DataGrid, GridFooterContainer } from "@mui/x-data-grid";
import { S3Client, DeleteObjectCommand } from "@aws-sdk/client-s3";

import SizeChart from "./SizeChart";
import { usePage } from "../context/PageContext";
import { useOrders } from "../context/OrdersContext";
import { updateOrderStatus, getTailors } from "../api";

const AllOrders = ({ totalPages, totalOrders }) => {
  const navigate = useNavigate();

  const { page, setPage } = usePage();
  const { allOrders, loadingOrders, fetchAllOrders, fetchOrdersByStatus } =
    useOrders();

  const [tailors, setTailors] = useState([]);
  const paginationModel = { page: 0, pageSize: 100 };
  const [selectionModel, setSelectionModel] = useState([]);

  const [alertMessage, setAlertMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");

  // ************** FETCH ORDERS BY STATUS ***************

  useEffect(() => {
    fetchOrdersByStatus();
  }, [fetchOrdersByStatus]);

  // ************** GET TAILORS ***************

  useEffect(() => {
    const fetchTailors = async () => {
      try {
        const data = await getTailors();
        setTailors(data?.tailor.reverse());
      } catch (error) {
        console.error("Error fetching tailors:", error);
      }
    };

    fetchTailors();
  }, []);

  // ************** TAILOR SELECT ***************

  const [selectedTailors, setSelectedTailors] = useState({});

  useEffect(() => {
    const initialSelectedTailors = {};
    allOrders.forEach((order) => {
      if (order.tailor) {
        initialSelectedTailors[order._id] = order.tailor._id;
      }
    });
    setSelectedTailors(initialSelectedTailors);
  }, [allOrders]);

  const handleSelectChange = async (id, value) => {
    setSelectedTailors((prev) => ({
      ...prev,
      [id]: value,
    }));

    const data = { orderId: id, tailor: value };

    try {
      await updateOrderStatus(data);
      setAlertMessage("Tailor assigned successfully!");
      setAlertSeverity("success");
      setOpenSnackbar(true);

      fetchAllOrders(page);
    } catch (error) {
      setAlertMessage("Error assigning tailor!");
      setAlertSeverity("error");
      setOpenSnackbar(true);

      console.error("Error assigning tailor:", error);
    }
  };

  // ************** MANAGE STATUS CHANGE ***************

  const [statusMap, setStatusMap] = useState({});

  useEffect(() => {
    if (allOrders) {
      const updatedStatusMap = allOrders.reduce((acc, curr) => {
        acc[curr._id] = curr.status;
        return acc;
      }, {});
      setStatusMap(updatedStatusMap);
    }
  }, [allOrders]);

  //************ DELETE ORDER IAMGES *********** */

  const s3Client = new S3Client({
    region: process.env.REACT_APP_AWS_S3_REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID || "",
      secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY || "",
    },
  });

  const deleteS3File = async (bucketName, key) => {
    try {
      if (typeof key !== "string") {
        key = key.toString();
      }

      const deleteParams = {
        Bucket: bucketName,
        Key: key.replace(/^https?:\/\/[^/]+\/(.+)$/, "$1"),
      };

      const command = new DeleteObjectCommand(deleteParams);
      await s3Client.send(command);
    } catch (error) {
      console.error("Error deleting file from S3:", error);
      throw new Error("Failed to delete file from S3");
    }
  };

  // ************** STATUS CHANGE ***************

  const handleStatusChange = async (orderId, newStatus) => {
    try {
      const userData = { orderId, status: newStatus };
      await updateOrderStatus(userData);

      setStatusMap((prev) => ({
        ...prev,
        [orderId]: newStatus,
      }));

      if (newStatus.toLowerCase() === "delivered") {
        const orderToUpdate = allOrders.find((order) => order._id === orderId);
        if (orderToUpdate) {
          const imagesToDelete = orderToUpdate.products.flatMap(
            (product) => product.design_images || []
          );

          // Delete all associated design images
          for (const refImg of imagesToDelete) {
            await deleteS3File(
              process.env.REACT_APP_AWS_S3_BUCKET_NAME || "",
              refImg
            );
          }

          // Delete payment evidence image if it exists
          if (orderToUpdate.payment_evidence) {
            await deleteS3File(
              process.env.REACT_APP_AWS_S3_BUCKET_NAME || "",
              orderToUpdate.payment_evidence
            );
          }
        }
      }

      fetchAllOrders(page);
      fetchOrdersByStatus();
      setAlertMessage("Status updated successfully!");
      setAlertSeverity("success");
      setOpenSnackbar(true);
    } catch (error) {
      setAlertMessage("Failed to update status.");
      setAlertSeverity("error");
      setOpenSnackbar(true);
      console.error("Error updating order status:", error);
    }
  };

  // ************** FORMAT DATE ***************

  const formatDate = (isoDate) => {
    if (!isoDate) return "";
    return new Date(isoDate).toISOString().split("T")[0];
  };

  // ************** PICKUP TRACKING ***************

  const [pickupTrackingIds, setPickupTrackingIds] = useState({});

  useEffect(() => {
    if (allOrders) {
      const initialTrackingIds = allOrders.reduce((acc, order) => {
        acc[order._id] = order.pickup_tracking_id || "";
        return acc;
      }, {});
      setPickupTrackingIds(initialTrackingIds);
    }
  }, [allOrders]);

  const handlePickupTrackingId = (id, value) => {
    setPickupTrackingIds((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  // ************** DELIVERY TRACKING ***************

  const [deliveryTrackingIds, setDeliveryTrackingIds] = useState({});

  useEffect(() => {
    if (allOrders) {
      const initialTrackingIds = allOrders.reduce((acc, order) => {
        acc[order._id] = order.delivery_tracking_id || "";
        return acc;
      }, {});
      setDeliveryTrackingIds(initialTrackingIds);
    }
  }, [allOrders]);

  const handleDeliveryTrackingId = (id, value) => {
    setDeliveryTrackingIds((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  // ************** ADVANCE PAID ***************

  const [advancePaid, setAdvancePaid] = useState({});

  useEffect(() => {
    if (allOrders) {
      const amount = allOrders.reduce((acc, order) => {
        acc[order._id] = order.advance_payment || "";
        return acc;
      }, {});
      setAdvancePaid(amount);
    }
  }, [allOrders]);

  const handleAdvancePaid = (id, value) => {
    setAdvancePaid((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  // ************** ADVANCE PAYMENT DATE ***************

  const [advancePaymentDate, setAdvancePaymentDate] = useState({});

  useEffect(() => {
    if (allOrders) {
      const amount = allOrders.reduce((acc, order) => {
        acc[order._id] = order.advance_payment_date || "";
        return acc;
      }, {});
      setAdvancePaymentDate(amount);
    }
  }, [allOrders]);

  const handleAdvancePaymentDate = (id, value) => {
    setAdvancePaymentDate((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  // ************** TAILOR INBOUND DATE ***************

  const [tailorInboundDate, setTailorInboundDate] = useState({});

  useEffect(() => {
    if (allOrders) {
      const date = allOrders.reduce((acc, order) => {
        acc[order._id] = order.tailor_inbound_date || "";
        return acc;
      }, {});
      setTailorInboundDate(date);
    }
  }, [allOrders]);

  const handleTailorInboundDate = (id, value) => {
    setTailorInboundDate((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  // ************** ADDITIONAL CHARGES ***************

  const [additionalCharges, setAdditionalCharges] = useState({});

  useEffect(() => {
    if (allOrders) {
      const amount = allOrders.reduce((acc, order) => {
        acc[order._id] = order.additional_charges || "";
        return acc;
      }, {});
      setAdditionalCharges(amount);
    }
  }, [allOrders]);

  const handleAdditionalCharges = (id, value) => {
    setAdditionalCharges((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  // ************** FINAL PAYMENT PAID ***************

  const [finalPaymentPaid, setFinalPaymentPaid] = useState({});

  useEffect(() => {
    if (allOrders) {
      const amount = allOrders.reduce((acc, order) => {
        acc[order._id] = order.final_payment_paid || "";
        return acc;
      }, {});
      setFinalPaymentPaid(amount);
    }
  }, [allOrders]);

  const handleFinalPaymentPaid = (id, value) => {
    setFinalPaymentPaid((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  // ************** FINAL PAYMENT DATE ***************

  const handleFinalPaymentDate = async (id) => {
    const currentDate = new Date().toISOString();

    try {
      await updateOrderStatus({
        orderId: id,
        final_payment_date: currentDate,
      });
    } catch (error) {
      console.error("Failed to add final payment date:", error);
    }
  };

  // ************** FINAL PAYMENT ACCOUNT ***************

  const [finalPaymentAccount, setFinalPaymentAccount] = useState({});

  useEffect(() => {
    if (allOrders) {
      const amount = allOrders.reduce((acc, order) => {
        acc[order._id] = order.final_payment_method || "";
        return acc;
      }, {});
      setFinalPaymentAccount(amount);
    }
  }, [allOrders]);

  const handleFinalPaymentAccount = async (id, value) => {
    setFinalPaymentAccount((prev) => ({
      ...prev,
      [id]: value,
    }));

    const account = {
      orderId: id,
      final_payment_method: value,
    };

    try {
      await updateOrderStatus(account);
      setAlertMessage("Added final payment account.");
      setAlertSeverity("success");
      setOpenSnackbar(true);
    } catch (error) {
      setAlertMessage("Failed to add final payment account!");
      setAlertSeverity("error");
      setOpenSnackbar(true);
      console.error("Failed to add final payment account:", error);
    }
  };

  // ************** REMAINING BALANCE ***************

  const [remainingBalances, setRemainingBalances] = useState({});

  useEffect(() => {
    const calculateRemainingBalances = () => {
      const balances = allOrders.reduce((acc, order) => {
        let balance =
          Number(order.order_price || 0) +
          Number(order.additional_charges || 0) -
          Number(order.advance_payment || 0) -
          Number(order.final_payment_paid || 0);

        acc[order._id] = balance;
        return acc;
      }, {});

      setRemainingBalances(balances);
    };

    calculateRemainingBalances();
  }, [allOrders]);

  useEffect(() => {
    const updateBalances = async () => {
      for (const order of allOrders) {
        const amount = {
          orderId: order._id,
          remaining_balance: remainingBalances[order._id],
        };

        try {
          await updateOrderStatus(amount);
        } catch (error) {
          console.error(
            `Failed to update remaining balance for order ${order._id}:`,
            error
          );
        }
      }
    };

    if (Object.keys(remainingBalances).length > 0) {
      updateBalances();
    }
  }, [allOrders, remainingBalances]);

  // ************** TABLE COLUMNS ***************

  const columns = [
    {
      field: "id",
      headerName: "Order ID",
      width: 210,
      renderCell: (params) => (
        <span
          onClick={(e) => handleRowClick(params.row, e)}
          style={{ cursor: "pointer", color: "#1976d2" }}
        >
          {params.value}
        </span>
      ),
    },
    { field: "customer_name", headerName: "Customer Name", width: 140 },
    {
      field: "status",
      headerName: "Order Status",
      width: 180,
      renderCell: (params) => (
        <select
          value={statusMap[params?.row?.id]}
          onClick={(e) => e.stopPropagation()}
          className="w-full h-9 border rounded-md cursor-pointer"
          onChange={(e) => handleStatusChange(params.row.id, e.target.value)}
        >
          <option value="booked">Booked</option>
          <option value="scheduled_for_pickup">Scheduled for pickup</option>
          <option value="arrived_at_filato">Arrived at Filato</option>
          <option value="in_process">In process</option>
          <option value="finishing">Finishing</option>
          <option value="out_for_delivery">Out for delivery</option>
          <option value="delivered">Delivered</option>
        </select>
      ),
    },
    { field: "contact", headerName: "Contact", width: 130 },
    { field: "order_date", headerName: "Order Date", width: 150 },
    {
      field: "pickup_date",
      headerName: "Pickup Date",
      width: 150,
      renderCell: (params) => {
        const status =
          params.row.pickup_date === "pending"
            ? "pending"
            : params.row.pickup_date;

        return (
          <span
            style={{
              color: status === "pending" && "orange",
            }}
          >
            {status}
          </span>
        );
      },
    },
    {
      field: "pickup_tracking_id",
      headerName: "Pickup Tracking ID",
      width: 150,
      renderCell: (params) => (
        <input
          type="text"
          placeholder="Enter tracking ID"
          onClick={(e) => e.stopPropagation()}
          value={pickupTrackingIds[params.row.id] || ""}
          className="w-full h-8 border rounded-md px-1"
          onChange={(e) =>
            handlePickupTrackingId(params.row.id, e.target.value)
          }
          onKeyDown={async (e) => {
            if (e.key === "Enter" && !e.repeat) {
              const trackingId = {
                orderId: params.row.id,
                pickup_tracking_id: e.target.value,
              };

              try {
                await updateOrderStatus(trackingId);
                setAlertMessage("Added pickup tracking ID.");
                setAlertSeverity("success");
                setOpenSnackbar(true);
              } catch (error) {
                setAlertMessage("Failed to add pickup tracking ID!");
                setAlertSeverity("error");
                setOpenSnackbar(true);
                console.error("Failed to add pickup tracking ID:", error);
              }
              e.target.blur();
            }
          }}
        />
      ),
    },
    {
      field: "receiving_date",
      headerName: "Receiving Date",
      width: 150,
      renderCell: (params) => {
        const status =
          params.row.receiving_date === "pending"
            ? "pending"
            : params.row.receiving_date;

        return (
          <span
            style={{
              color: status === "pending" && "orange",
            }}
          >
            {status}
          </span>
        );
      },
    },
    {
      field: "tcs_pickup",
      headerName: "TCS Pickup Job",
      width: 120,
      renderCell: (params) => {
        const status =
          params.row.receiving_date === "pending" ? "pending" : "completed";

        return (
          <span
            style={{
              color: status === "completed" ? "green" : "red",
            }}
          >
            {status}
          </span>
        );
      },
    },
    {
      field: "tailor",
      headerName: "Tailor",
      width: 150,
      renderCell: (params) => (
        <select
          onClick={(e) => e.stopPropagation()}
          value={selectedTailors[params.row.id] || ""}
          onChange={(e) => handleSelectChange(params.row.id, e.target.value)}
          className="w-full h-9 border rounded-md cursor-pointer"
        >
          <option value="" disabled>
            Select
          </option>

          {tailors?.map((tailor) => (
            <option key={tailor._id} value={tailor._id}>
              {tailor.name}
            </option>
          ))}
        </select>
      ),
    },
    {
      field: "tailor_outbound_date",
      headerName: "Tailor Outbound Date",
      width: 160,
      renderCell: (params) => {
        const status =
          params.row.tailor_outbound_date === "pending"
            ? "pending"
            : params.row.tailor_outbound_date;

        return (
          <span
            style={{
              color: status === "pending" ? "orange" : null,
            }}
          >
            {status}
          </span>
        );
      },
    },
    {
      field: "tailor_inbound_date",
      headerName: "Tailor Inbound Date",
      width: 150,
      renderCell: (params) => (
        <input
          type="date"
          onClick={(e) => e.stopPropagation()}
          value={formatDate(tailorInboundDate[params.row.id]) || ""}
          className="w-full h-8 border rounded-md cursor-pointer px-1"
          onChange={async (e) => {
            handleTailorInboundDate(params.row.id, e.target.value);

            const date = {
              orderId: params.row.id,
              tailor_inbound_date: e.target.value,
            };

            try {
              await updateOrderStatus(date);
              setAlertMessage("Added tailor inbound date.");
              setAlertSeverity("success");
              setOpenSnackbar(true);
            } catch (error) {
              setAlertMessage("Failed to add tailor inbound date!");
              setAlertSeverity("error");
              setOpenSnackbar(true);
              console.error("Failed to add tailor inbound date:", error);
            }
          }}
        />
      ),
    },
    {
      field: "dispatch_date",
      headerName: "Dispatch Date",
      width: 150,
      renderCell: (params) => {
        const status =
          params.row.dispatch_date === "pending"
            ? "pending"
            : params.row.dispatch_date;

        return (
          <span
            style={{
              color: status === "pending" && "orange",
            }}
          >
            {status}
          </span>
        );
      },
    },
    {
      field: "delivery_tracking_id",
      headerName: "Delivery Tracking ID",
      width: 150,
      renderCell: (params) => (
        <input
          type="text"
          placeholder="Enter tracking ID"
          onClick={(e) => e.stopPropagation()}
          value={deliveryTrackingIds[params.row.id] || ""}
          className="w-full h-8 border rounded-md px-1"
          onChange={(e) =>
            handleDeliveryTrackingId(params.row.id, e.target.value)
          }
          onKeyDown={async (e) => {
            if (e.key === "Enter") {
              const trackingId = {
                orderId: params.row.id,
                delivery_tracking_id: e.target.value,
              };

              try {
                await updateOrderStatus(trackingId);
                setAlertMessage("Added delivery tracking ID.");
                setAlertSeverity("success");
                setOpenSnackbar(true);
              } catch (error) {
                setAlertMessage("Failed to add delivery tracking ID!");
                setAlertSeverity("error");
                setOpenSnackbar(true);
                console.error("Failed to add delivery tracking ID:", error);
              }
              e.target.blur();
            }
          }}
        />
      ),
    },
    {
      field: "delivered_date",
      headerName: "Delivered Date",
      width: 150,
      renderCell: (params) => {
        const status =
          params.row.delivered_date === "pending"
            ? "pending"
            : params.row.delivered_date;

        return (
          <span
            style={{
              color: status === "pending" && "orange",
            }}
          >
            {status}
          </span>
        );
      },
    },
    {
      field: "tcs_delivered",
      headerName: "TCS Delivery Job",
      width: 130,
      renderCell: (params) => {
        const status =
          params.row.delivered_date === "pending" ? "pending" : "completed";

        return (
          <span
            style={{
              color: status === "completed" ? "green" : "red",
            }}
          >
            {status}
          </span>
        );
      },
    },
    { field: "order_total", headerName: "Order Total" },
    {
      field: "advance_payment",
      headerName: "Advance Paid",
      width: 130,
      renderCell: (params) => (
        <input
          type="number"
          placeholder="Enter amount"
          onClick={(e) => e.stopPropagation()}
          value={advancePaid[params.row.id] || ""}
          className="w-full h-8 border rounded-md px-1"
          onChange={(e) => handleAdvancePaid(params.row.id, e.target.value)}
          onKeyDown={async (e) => {
            if (e.key === "Enter") {
              const amount = {
                orderId: params.row.id,
                advance_payment: e.target.value,
              };

              try {
                await updateOrderStatus(amount);
                setAlertMessage("Added advance amount paid.");
                setAlertSeverity("success");
                setOpenSnackbar(true);
                fetchAllOrders(page);
              } catch (error) {
                setAlertMessage("Failed to add advance amount paid!");
                setAlertSeverity("error");
                setOpenSnackbar(true);
                console.error("Failed to add advance amount paid:", error);
              }
              e.target.blur();
            }
          }}
        />
      ),
    },
    {
      field: "advance_payment_date",
      headerName: "Advance Payment Date",
      width: 170,
      renderCell: (params) => (
        <input
          type="date"
          onClick={(e) => e.stopPropagation()}
          value={formatDate(advancePaymentDate[params.row.id]) || ""}
          className="w-full h-8 border rounded-md cursor-pointer px-1"
          onChange={async (e) => {
            handleAdvancePaymentDate(params.row.id, e.target.value);

            const amount = {
              orderId: params.row.id,
              advance_payment_date: e.target.value,
            };

            try {
              await updateOrderStatus(amount);
              setAlertMessage("Added advance payment date.");
              setAlertSeverity("success");
              setOpenSnackbar(true);
            } catch (error) {
              setAlertMessage("Failed to add advance payment date!");
              setAlertSeverity("error");
              setOpenSnackbar(true);

              console.error("Failed to add advance payment date:", error);
            }
          }}
        />
      ),
    },
    {
      field: "advance_payment_method",
      headerName: "Advance Payment Method",
      width: 190,
    },
    {
      field: "additional_charges",
      headerName: "Additional Charges",
      width: 140,
      renderCell: (params) => (
        <input
          type="number"
          placeholder="Enter amount"
          onClick={(e) => e.stopPropagation()}
          value={additionalCharges[params.row.id] || ""}
          className="w-full h-8 border rounded-md px-1"
          onChange={(e) =>
            handleAdditionalCharges(params.row.id, e.target.value)
          }
          onKeyDown={async (e) => {
            if (e.key === "Enter") {
              const amount = {
                orderId: params.row.id,
                additional_charges: e.target.value,
              };

              try {
                await updateOrderStatus(amount);
                setAlertMessage("Added additional charges.");
                setAlertSeverity("success");
                setOpenSnackbar(true);
                fetchAllOrders(page);
              } catch (error) {
                setAlertMessage("Failed to add additional charges!");
                setAlertSeverity("error");
                setOpenSnackbar(true);

                console.error("Failed to add additional charges:", error);
              }
              e.target.blur();
            }
          }}
        />
      ),
    },
    {
      field: "amount_pending",
      headerName: "Amount Pending",
      width: 140,
    },
    {
      field: "final_payment_paid",
      headerName: "Final Payment Paid",
      width: 150,
      renderCell: (params) => (
        <input
          type="number"
          placeholder="Enter amount"
          onClick={(e) => e.stopPropagation()}
          value={finalPaymentPaid[params.row.id] || ""}
          className="w-full h-8 border rounded-md px-1"
          onChange={(e) =>
            handleFinalPaymentPaid(params.row.id, e.target.value)
          }
          onKeyDown={async (e) => {
            if (e.key === "Enter") {
              const amount = {
                orderId: params.row.id,
                final_payment_paid: e.target.value,
              };

              try {
                await updateOrderStatus(amount);
                setAlertMessage("Added final payment paid.");
                setAlertSeverity("success");
                setOpenSnackbar(true);

                handleFinalPaymentDate(params.row.id);
                fetchAllOrders(page);
              } catch (error) {
                setAlertMessage("Failed to add final payment paid!");
                setAlertSeverity("error");
                setOpenSnackbar(true);

                console.error("Failed to add final payment paid:", error);
              }
              e.target.blur();
            }
          }}
        />
      ),
    },
    {
      field: "final_payment_date",
      headerName: "Final Payment Date",
      width: 150,
      renderCell: (params) => {
        const status =
          params.row.final_payment_date === "pending"
            ? "pending"
            : params.row.final_payment_date;

        return (
          <span
            style={{
              color: status === "pending" ? "orange" : null,
            }}
          >
            {status}
          </span>
        );
      },
    },
    {
      field: "final_payment_account",
      headerName: "Final Payment Account",
      width: 170,
      renderCell: (params) => {
        return (
          <select
            onClick={(e) => e.stopPropagation()}
            value={finalPaymentAccount[params.row.id] || ""}
            className="w-full h-9 border rounded-md cursor-pointer"
            onChange={(e) =>
              handleFinalPaymentAccount(params.row.id, e.target.value)
            }
          >
            <option value="" disabled>
              Select
            </option>
            <option value="bank_transfer">Bank Transfer</option>
            <option value="payoneer">Payoneer</option>
          </select>
        );
      },
    },
    { field: "remaining_balance", headerName: "Remaining Balance", width: 150 },
    { field: "delivery_address", headerName: "Delivery Address", width: 250 },
    { field: "pickup_address", headerName: "Pickup Address", width: 250 },
  ];

  // ************** TABLE ROWS ***************

  const rows = allOrders?.map((order) => ({
    id: order._id,
    customer_name: order.userId.name,
    status: order.status,
    contact: `+${order.userId.phone_number}`,
    order_date: new Date(order.createdAt).toLocaleDateString("en-US", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    }),
    pickup_date: order.pickup_date
      ? new Date(order.pickup_date).toLocaleDateString("en-US", {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        })
      : "pending",
    pickup_tracking_id: order.pickup_tracking_id,
    receiving_date: order.receiving_date
      ? new Date(order.receiving_date).toLocaleDateString("en-US", {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        })
      : "pending",
    tcs_pickup: order.receiving_date ? "completed" : "pending",
    tailor: order.tailor?.name,
    tailor_outbound_date: order.tailor_outbound_date
      ? new Date(order.tailor_outbound_date).toLocaleDateString("en-US", {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        })
      : "pending",
    tailor_inbound_date: order.tailor_inbound_date,
    dispatch_date: order.dispatch_date
      ? new Date(order.dispatch_date).toLocaleDateString("en-US", {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        })
      : "pending",
    delivery_tracking_id: order.delivery_tracking_id,
    delivered_date: order.delivered_date
      ? new Date(order.delivered_date).toLocaleDateString("en-US", {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        })
      : "pending",
    tcs_delivered: order.delivered_date ? "completed" : "pending",
    order_total: order.order_price,
    advance_payment: order.advance_payment,
    advance_payment_date: order.advance_payment_date,
    advance_payment_method: order.payment_mode,
    additional_charges: order.additional_charges,
    amount_pending:
      Number(order.order_price) +
      (order.additional_charges ? Number(order.additional_charges) : 0) -
      (order.advance_payment ? Number(order.advance_payment) : 0),
    final_payment_paid: order.final_payment_paid,
    final_payment_date: order.final_payment_date
      ? new Date(order.final_payment_date).toLocaleDateString("en-US", {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        })
      : "pending",
    final_payment_account: order.final_payment_method,
    remaining_balance:
      remainingBalances[order._id] === order.order_price
        ? 0
        : remainingBalances[order._id],
    delivery_address: order.delivery_address,
    pickup_address: order.pickup_address ? order.pickup_address : "N/A",
    products: order.products,
  }));

  // ************** OPENING ORDER DETAIL PAGE ***************

  const handleRowClick = (params, event) => {
    if (!event.target.closest("select")) {
      const orderId = params.id;
      const selectedOrder = allOrders.find((o) => o._id === orderId);
      navigate(`/order/${orderId}`, {
        state: { order: selectedOrder, allOrders: allOrders },
      });
    }
  };

  // ************** TRACK ORDERS ***************

  const handleTracking = () => {
    const selectedOrders = rows.filter((row) =>
      selectionModel.includes(row.id)
    );

    const filteredOrders = selectedOrders.map((order) => ({
      order_id: order.id,
      customer_name: order.customer_name,
      customer_contact: order.contact,
      tracking_id: "",
      delivery_address: order.delivery_address,
      pickup_address: order.pickup_address,
    }));

    // Create a worksheet from the filtered data
    const ws = XLSX.utils.json_to_sheet(filteredOrders);

    ws["!cols"] = [
      { wch: 25 }, // Order ID column width
      { wch: 20 }, // Customer Name column width
      { wch: 15 }, // Customer Contact column width
      { wch: 25 }, // Tracking ID column width
      { wch: 35 }, // Delivery Address column width
      { wch: 35 }, // Pickup Address column width
    ];

    const header = [
      "Order ID",
      "Customer Name",
      "Customer Contact",
      "Tracking ID",
      "Delivery Address",
      "Pickup Address",
    ];

    // Insert custom headers at the top of the worksheet
    header.forEach((headerText, index) => {
      const cellRef = XLSX.utils.encode_cell({ r: 0, c: index });
      ws[cellRef] = { v: headerText, t: "s" };
    });

    // Create a workbook and add the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Orders");

    // Generate an Excel file and trigger download
    XLSX.writeFile(wb, "track_orders.xlsx");
  };

  // ************** MANAGE ORDERS ***************

  const handleManageOrders = () => {
    const selectedOrders = rows.filter((row) =>
      selectionModel.includes(row.id)
    );

    const filteredOrders = selectedOrders.map((order) => ({
      order_id: order.id,
      customer_name: order.customer_name,
      status: order.status,
      customer_contact: order.contact,
      order_date: new Date(order.order_date).toLocaleDateString("en-US", {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
      }),
      pickup_date: order.pickup_date,
      pickup_tracking_id: order.pickup_tracking_id,
      receiving_date: order.receiving_date,
      tcs_pickup_job: order.receiving_date ? "Completed" : "Pending",
      tailor: order.tailor,
      tailor_outbound_date: order.tailor_outbound_date,
      tailor_inbound_date: order.tailor_inbound_date
        ? new Date(order.tailor_inbound_date).toLocaleDateString("en-US", {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
          })
        : "N/A",
      dispatch_date: order.dispatch_date,
      delivery_tracking_id: order.delivery_tracking_id,
      delivered_date: order.delivered_date,
      tcs_delivery_job: order.delivered_date ? "Completed" : "Pending",
      order_total: order.order_total,
      advancePaid: order.advance_payment,
      advance_payment_date: order.advance_payment_date
        ? new Date(order.advance_payment_date).toLocaleDateString("en-US", {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
          })
        : "N/A",
      advance_payment_method: order.advance_payment_method,
      additional_charges: order.additional_charges,
      amount_pending: order.amount_pending,
      final_payment_paid: order.final_payment_paid,
      final_payment_date: order.final_payment_date,
      final_payment_account: order.final_payment_account,
      remaining_balance: order.remaining_balance,
      delivery_address: order.delivery_address,
      pickup_address: order.pickup_address,
    }));

    // Create a worksheet from the filtered data
    const ws = XLSX.utils.json_to_sheet(filteredOrders);

    ws["!cols"] = [
      { wch: 25 }, // Order ID column width
      { wch: 20 }, // Customer Name column width
      { wch: 15 }, // Order Status width
      { wch: 15 }, // Customer Contact column width
      { wch: 20 },
      { wch: 20 },
      { wch: 25 }, // Pickup Tracking ID column width
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 25 }, // Delivery Tracking ID column width
      { wch: 20 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 20 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 20 },
      { wch: 15 },
      { wch: 15 },
      { wch: 35 }, // Delivery Address column width
      { wch: 35 }, // Pickup Address column width
    ];

    const header = [
      "Order ID",
      "Customer Name",
      "Order Status",
      "Customer Contact",
      "Order Date",
      "Pickup date",
      "Pickup Tracking ID",
      "Receiving Date",
      "TCS Pickup Job",
      "Tailor",
      "Tailor Outbound Date",
      "Tailor Inbound Date",
      "Dispatch Date",
      "Delivery Tracking ID",
      "Delivered Date",
      "TCS Delivery Job",
      "Order Total",
      "Advance Paid",
      "Advance Payment Date",
      "Advance Payment Method",
      "Additional Charges",
      "Amount Pending",
      "Final Payment Paid",
      "Final Payment Date",
      "Final Payment Account",
      "Remaining Balance",
      "Delivery Address",
      "Pickup Address",
    ];

    // Insert custom headers at the top of the worksheet
    header.forEach((headerText, index) => {
      const cellRef = XLSX.utils.encode_cell({ r: 0, c: index });
      ws[cellRef] = { v: headerText, t: "s" };
    });

    // Create a workbook and add the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Orders");

    // Generate an Excel file and trigger download
    XLSX.writeFile(wb, "manage_orders.xlsx");
  };

  // ************** PRINT ORDERS ***************

  const handlePrint = () => {
    const selectedOrders = rows.filter((row) =>
      selectionModel.includes(row.id)
    );

    const printContent = [];

    // Build the content for the print window without opening it yet
    selectedOrders.forEach((order) => {
      const selectedOrder = order.products || [];

      selectedOrder.forEach((product) => {
        const sizeMeasurements = product.size
          ? SizeChart.map(
              (measurement) => `
              <tr>
                <td>${measurement.measurement}</td>
                <td>${measurement[product.size] || "N/A"}</td>
              </tr>
            `
            ).join("")
          : "";

        let orderHtml = `
          <div class="order detail-item">
            <div><strong>Customer Name:</strong> ${order.customer_name}</div>
            <h2>Order ID: ${order.id}</h2>
            ${
              product.dress_type
                ? `<div><strong>Dress Type:</strong> ${product.dress_type}</div>`
                : ""
            }
            ${
              product.dress_fit_type
                ? `<div><strong>Dress Fit:</strong> ${product.dress_fit_type}</div>`
                : ""
            }
            ${
              product.top_style
                ? `<div><strong>Top Style:</strong> ${product.top_style}</div>`
                : ""
            }
            ${
              product.neck_style
                ? `<div><strong>Neck Style:</strong> ${product.neck_style}</div>`
                : ""
            }
            ${
              product.sleeves_style
                ? `<div><strong>Sleeves Style:</strong> ${product.sleeves_style}</div>`
                : ""
            }
            ${
              product.bottom_style
                ? `<div><strong>Bottom Style:</strong> ${product.bottom_style}</div>`
                : ""
            }
            ${
              order.message
                ? `<div><strong>Other Information:</strong> ${order.message}</div>`
                : ""
            }
            ${
              product.sizeId
                ? `<div><strong>Custom Size (Inches)</strong></div>`
                : `<div><strong>Size - ${product.size}</strong></div>`
            }
            ${
              product.sizeId
                ? `
                <table>
                  <thead>
                    <tr>
                      <th>Measurement</th>
                      <th>Value (inches)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Neck</td>
                      <td>${product.sizeId.neck}</td>
                    </tr>
                    <tr>
                      <td>Shoulder</td>
                      <td>${product.sizeId.shoulder}</td>
                    </tr>
                    <tr>
                      <td>Sleeves</td>
                      <td>${product.sizeId.sleeves}</td>
                    </tr>
                    <tr>
                      <td>Arm Hole</td>
                      <td>${product.sizeId.arm_hole}</td>
                    </tr>
                    <tr>
                      <td>Bicep</td>
                      <td>${product.sizeId.bicep}</td>
                    </tr>
                    <tr>
                      <td>Wrist</td>
                      <td>${product.sizeId.wrist}</td>
                    </tr>
                    <tr>
                      <td>Chest</td>
                      <td>${product.sizeId.chest}</td>
                    </tr>
                    <tr>
                      <td>Back Chest</td>
                      <td>${product.sizeId.back_chest}</td>
                    </tr>
                    <tr>
                      <td>Waist</td>
                      <td>${product.sizeId.waist}</td>
                    </tr>
                    <tr>
                      <td>Hip</td>
                      <td>${product.sizeId.hip}</td>
                    </tr>
                    <tr>
                      <td>Top Length</td>
                      <td>${product.sizeId.top_length}</td>
                    </tr>
                    <tr>
                      <td>Bottom Length</td>
                      <td>${product.sizeId.bottom_length}</td>
                    </tr>
                  </tbody>
                </table>`
                : `
                <table>
                  <thead>
                    <tr>
                      <th>Measurement</th>
                      <th>Value (inches)</th>
                    </tr>
                  </thead>
                  <tbody>${sizeMeasurements}</tbody>
                </table>
                `
            }
          </div>
        `;

        // Add images if they exist
        if (
          Array.isArray(product.design_images) &&
          product.design_images?.length > 0
        ) {
          product.design_images.forEach((image) => {
            orderHtml += `
              <div class="order design-image">
                <h2>Order ID: ${order.id}</h2>
                <div><strong>Customer Name:</strong> ${
                  order.customer_name
                }</div>
                ${
                  product.dress_type
                    ? `<div><strong>Dress Type:</strong> ${product.dress_type}</div>`
                    : ""
                }
                ${
                  product.dress_fit_type
                    ? `<div><strong>Dress Fit:</strong> ${product.dress_fit_type}</div>`
                    : ""
                }
                ${
                  product.top_style
                    ? `<div><strong>Top Style:</strong> ${product.top_style}</div>`
                    : ""
                }
                ${
                  product.neck_style
                    ? `<div><strong>Neck Style:</strong> ${product.neck_style}</div>`
                    : ""
                }
                ${
                  product.sleeves_style
                    ? `<div><strong>Sleeves Style:</strong> ${product.sleeves_style}</div>`
                    : ""
                }
                ${
                  product.bottom_style
                    ? `<div><strong>Bottom Style:</strong> ${product.bottom_style}</div>`
                    : ""
                }
                <img src=${image} alt="Design for Order ID ${
              order.id
            }" style="max-width: 100%; height: auto;"/>
              </div>
            `;
          });
        }

        // Add a page break after each product
        orderHtml += '<div style="page-break-after: always;"></div>';
        printContent.push(orderHtml);
      });
    });

    // Create a function to check if all images are loaded
    const loadImages = (htmlContent) => {
      return new Promise((resolve) => {
        const tempDiv = document.createElement("div");

        tempDiv.innerHTML = htmlContent;

        // Find all images in the generated HTML
        const imgs = tempDiv.getElementsByTagName("img");

        if (imgs?.length === 0) {
          resolve(); // No images to load
          return;
        }

        let loadedImages = 0;

        Array.from(imgs).forEach((img) => {
          img.onload = () => {
            loadedImages += 1;
            if (loadedImages === imgs?.length) {
              resolve(); // All images loaded
            }
          };
          img.onerror = () => {
            loadedImages += 1; // Count errored images as loaded
            if (loadedImages === imgs?.length) {
              resolve();
            }
          };
        });
      });
    };

    // Combine the content into a single string
    const finalContent = `
      <html>
        <head>
          <title>Print Orders</title>
          <style>
            body { font-family: Arial, sans-serif; }
            .order { margin-bottom: 20px; padding: 10px; border: 1px solid black; }
            .order h2 { margin: 0 0 10px; }
            .order div { margin-bottom: 5px; }
            table { width: 100%; border-collapse: collapse; margin-top: 10px; }
            th, td { border: 1px solid black; padding: 8px; text-align: left; }
            .detail-item { display: flex; flex-direction: column; gap: 5px; } 
            img { max-width: 100%; height: auto; margin-top: 10px; }
            .design-image { page-break-before: always; }
          </style>
        </head>
        <body>
          <h1>Selected Orders</h1>
          ${printContent.join("")}
        </body>
      </html>
    `;

    // Load images and then open the print window
    loadImages(finalContent).then(() => {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(finalContent);
      printWindow.document.close();
      printWindow.print();
    });
  };

  // ************** PAGINATION CHANGE ***************

  const handlePaginationChange = (newPaginationModel) => {
    setPage(newPaginationModel.page);
  };

  // ************** CUSTOM PAGINATION ***************

  const CustomPagination = () => (
    <GridFooterContainer>
      <div style={{ margin: "auto" }}>
        Page {page + 1} of {totalPages}
      </div>
    </GridFooterContainer>
  );

  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          sx={{ width: "100%" }}
          severity={alertSeverity}
          onClose={() => setOpenSnackbar(false)}
        >
          {alertMessage}
        </Alert>
      </Snackbar>

      <div className="flex flex-col gap-3 bg-white py-5 px-3 rounded-lg border border-neutral">
        <div className="flex items-center justify-between h-10">
          <h3 className="text-xl font-medium">All Orders</h3>

          {selectionModel?.length > 0 && (
            <div className="flex items-center gap-2">
              <button
                onClick={handleTracking}
                className="bg-secondary text-white font-medium py-1 text-sm px-3 rounded"
              >
                Track Orders
              </button>

              <button
                onClick={handlePrint}
                className="bg-green text-white font-medium py-1 text-sm px-3 rounded"
              >
                Print Orders
              </button>

              <button
                onClick={handleManageOrders}
                className="bg-orange-300 font-medium py-1 text-sm px-3 rounded"
              >
                Manage Orders
              </button>
            </div>
          )}
        </div>

        {loadingOrders ? (
          <div className="flex flex-col bg-light animate-pulse rounded">
            <div className="flex items-center gap-4 justify-between bg-neutral p-4 rounded">
              <Skeleton
                width={25}
                height={40}
                variant="text"
                animation="wave"
              />
              <Skeleton
                width={150}
                height={40}
                variant="text"
                animation="wave"
              />
              <Skeleton
                width={150}
                height={40}
                variant="text"
                animation="wave"
              />
              <Skeleton
                width={150}
                height={40}
                variant="text"
                animation="wave"
              />
              <Skeleton
                width={150}
                height={40}
                variant="text"
                animation="wave"
              />
              <Skeleton
                width={150}
                height={40}
                variant="text"
                animation="wave"
              />
            </div>

            <div className="flex flex-col p-4">
              {Array(4)
                .fill(null)
                .map((_, index) => (
                  <div
                    key={index}
                    className="flex items-center gap-4 justify-between"
                  >
                    <Skeleton
                      width={25}
                      height={40}
                      variant="text"
                      animation="wave"
                    />
                    <Skeleton
                      width={150}
                      height={40}
                      variant="text"
                      animation="wave"
                    />
                    <Skeleton
                      width={150}
                      height={40}
                      variant="text"
                      animation="wave"
                    />
                    <Skeleton
                      width={150}
                      height={40}
                      variant="text"
                      animation="wave"
                    />
                    <Skeleton
                      width={150}
                      height={40}
                      variant="text"
                      animation="wave"
                    />
                    <Skeleton
                      width={150}
                      height={40}
                      variant="text"
                      animation="wave"
                    />
                  </div>
                ))}
            </div>
          </div>
        ) : allOrders?.length > 0 ? (
          <div className="max-h-[500px]">
            <DataGrid
              rows={rows}
              columns={columns}
              checkboxSelection
              paginationMode="server"
              rowCount={totalOrders}
              loading={loadingOrders}
              pageSizeOptions={[100]}
              disableRowSelectionOnClick
              paginationModel={paginationModel}
              onPaginationModelChange={handlePaginationChange}
              components={{
                Footer: CustomPagination,
              }}
              onRowSelectionModelChange={(newSelection) => {
                setSelectionModel(newSelection);
              }}
              sx={{
                cursor: "pointer",
                "& .MuiDataGrid-row": {
                  backgroundColor: "#fafafa", // Row background color
                },
                "& .MuiDataGrid-cell:focus": {
                  outline: "none", // Remove focus outline
                },
                "& .MuiDataGrid-cell:hover": {
                  outline: "none", // Remove hover outline
                },
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "#3c4348",
                  color: "white",
                  userSelect: "none", // Prevent text selection in the header
                  outline: "none", // Remove outline from header
                },
                "& .MuiDataGrid-columnHeader:focus": {
                  outline: "none", // Remove the blue border (focus outline) on column header
                },
                "& .MuiDataGrid-columnHeader:active": {
                  outline: "none", // Remove the outline when the column header is active
                },
                "& .MuiDataGrid-sortIcon": {
                  color: "white", // Sorting arrows color
                },
                "& .MuiDataGrid-menuIconButton": {
                  color: "white", // Three-dot menu button color
                },
              }}
            />
          </div>
        ) : (
          <div className="flex items-center justify-center text-lg">
            No active orders yet!
          </div>
        )}
      </div>
    </>
  );
};

export default AllOrders;
