import { Dialog } from "@mui/material";
import { Skeleton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import "react-toastify/dist/ReactToastify.css";
import { MdEdit, MdDelete } from "react-icons/md";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

import { getTailors, createTailor, updateTailor, deleteTailor } from "../api";

const Tailors = () => {
  const [tailors, setTailors] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [loading, setLoading] = useState(true);
  const [addClick, setAddClick] = useState(false);
  const paginationModel = { page: 0, pageSize: 5 };
  const [editClick, setEditClick] = useState(false);
  const [deleteClick, setDeleteClick] = useState(false);
  const [selectedTailor, setSelectedTailor] = useState(null);

  const [newTailor, setNewTailor] = useState({
    name: "",
    contact: "",
    address: "",
  });

  // ************** GET TAILORS ***************

  const fetchTailors = async () => {
    try {
      const data = await getTailors();
      setTailors(data?.tailor.reverse());
    } catch (error) {
      toast.error("Error fetching tailor data!");
      console.error("Failed to fetch tailors:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTailors();
  }, []);

  // ************** CREATE TAILOR ***************

  const handleAdd = async () => {
    if (!newTailor.name.trim()) {
      toast.error("Name is required!");
      return;
    }

    try {
      const addedTailor = await createTailor(newTailor);
      setTailors((prevTailors) =>
        prevTailors.map((tailor) =>
          tailor._id === addedTailor._id ? addedTailor : tailor
        )
      );

      setAddClick(false);
      setNewTailor({ name: "", contact: "", address: "" });

      fetchTailors();
    } catch (error) {
      toast.error("Error adding a new tailor!");
      console.error("Failed to add tailor:", error);
    }
  };

  // ************** UPDATE TAILOR ***************

  const openEditDialog = (tailor) => {
    setSelectedTailor(tailor);
    setEditClick(true);
  };

  const handleUpdate = async () => {
    try {
      const updatedTailor = await updateTailor(selectedTailor);
      setTailors((prevTailors) =>
        prevTailors.map((tailor) =>
          tailor._id === updatedTailor._id ? updatedTailor : tailor
        )
      );

      setEditClick(false);
      setSelectedTailor(null);

      toast.success("Tailor updated successfully!");
      fetchTailors();
    } catch (error) {
      toast.error("Error updating tailor!");
      console.error("Failed to update tailor:", error);
    }
  };

  // ************** DELETE TAILOR ***************

  const confirmDelete = (tailor) => {
    setDeleteId(tailor._id);
    setSelectedTailor(tailor.name);
    setDeleteClick(true);
  };

  const handleDelete = async () => {
    try {
      await deleteTailor(deleteId);
      setTailors((prevTailors) =>
        prevTailors.filter((tailor) => tailor._id !== deleteId)
      );
      toast.success("Deleted tailor successfully!");
      setDeleteClick(false);
    } catch (error) {
      toast.error("Error deleting tailor!");
      console.error("Failed to delete tailor:", error);
    }
  };

  // ************** COLUMNS ***************

  const columns = [
    { field: "_id", headerName: "ID", flex: 1 },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "contact", headerName: "Contact", flex: 1 },
    { field: "address", headerName: "Address", flex: 1.5 },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      flex: 0.7,
      renderCell: (params) => (
        <div className="flex items-center h-full gap-3">
          <MdEdit
            size={20}
            onClick={() => openEditDialog(params.row)}
            className="text-sky-600 cursor-pointer"
          />
          <MdDelete
            size={20}
            onClick={() => confirmDelete(params.row)}
            className="text-red-600 cursor-pointer"
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <ToastContainer autoClose={2000} position="top-right" />

      {loading ? (
        <div className="grid grid-cols-1 gap-3">
          <div className="grid md:grid-cols-3 grid-cols-1 gap-3">
            <div className="flex flex-col bg-white rounded-lg border border-neutral p-5">
              <Skeleton
                width={100}
                height={40}
                variant="text"
                animation="wave"
              />
              <Skeleton width={220} variant="text" animation="wave" />
            </div>

            <div className="flex flex-col bg-white rounded-lg border border-neutral p-5">
              <Skeleton
                width={100}
                height={40}
                variant="text"
                animation="wave"
              />
              <Skeleton width={220} variant="text" animation="wave" />
            </div>
          </div>

          <div className="flex flex-col gap-2 bg-white rounded-lg border border-neutral rounded p-5">
            <div className="flex items-center justify-between">
              <Skeleton
                width={200}
                height={40}
                variant="text"
                animation="wave"
              />
              <Skeleton
                width={150}
                height={70}
                variant="text"
                animation="wave"
              />
            </div>

            <div className="flex flex-col bg-light animate-pulse rounded">
              <div className="flex items-center gap-4 justify-between bg-neutral p-4 rounded">
                {Array(5)
                  .fill(null)
                  .map((_, index) => (
                    <Skeleton
                      key={index}
                      width={150}
                      height={40}
                      variant="text"
                      animation="wave"
                    />
                  ))}
              </div>

              <div className="flex flex-col p-4">
                {Array(3)
                  .fill(null)
                  .map((_, index) => (
                    <div
                      key={index}
                      className="flex items-center gap-4 justify-between"
                    >
                      {Array(5)
                        .fill(null)
                        .map((_, index) => (
                          <Skeleton
                            key={index}
                            width={150}
                            height={40}
                            variant="text"
                            animation="wave"
                          />
                        ))}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-3">
          {/* <div className="grid md:grid-cols-3 grid-cols-1 gap-3">
            {tailors?.map((tailor) => (
              <div
                key={tailor._id}
                className="flex flex-col bg-white rounded-lg border border-neutral p-5"
              >
                <span className="font-semibold text-4xl text-secondary">0</span>
                <span>
                  orders are assigned to{" "}
                  <span className="font-medium">{tailor.name}</span>
                </span>
              </div>
            ))}
          </div> */}

          <div className="flex flex-col gap-3 bg-white rounded-lg border border-neutral rounded p-5">
            <div className="flex items-center justify-between">
              <h1 className="text-2xl font-semibold">All Tailors</h1>
              <button
                onClick={() => setAddClick(true)}
                className="px-4 py-2 bg-secondary text-white rounded"
              >
                Add New Tailor
              </button>
            </div>

            <DataGrid
              pageSize={5}
              rows={tailors}
              columns={columns}
              selectionModel={[]}
              disableSelectionOnClick
              pageSizeOptions={[5, 10]}
              checkboxSelection={false}
              getRowId={(row) => row._id}
              isRowSelectable={() => false}
              onSelectionModelChange={() => {}}
              initialState={{
                pagination: { paginationModel },
              }}
              sx={{
                cursor: "pointer",
                ".MuiDataGrid-row.Mui-selected": {
                  backgroundColor: "inherit", // Prevent selected row highlighting
                },
                "& .MuiDataGrid-row": {
                  backgroundColor: "#fafafa", // Row background color
                },
                "& .MuiDataGrid-cell:focus": {
                  outline: "none", // Remove focus outline
                },
                "& .MuiDataGrid-cell:hover": {
                  outline: "none", // Remove hover outline
                },
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "#3c4348",
                  color: "white",
                  userSelect: "none", // Prevent text selection in the header
                  outline: "none", // Remove outline from header
                },
                "& .MuiDataGrid-columnHeader:focus": {
                  outline: "none", // Remove the blue border (focus outline) on column header
                },
                "& .MuiDataGrid-columnHeader:active": {
                  outline: "none", // Remove the outline when the column header is active
                },
                "& .MuiDataGrid-sortIcon": {
                  color: "white", // Sorting arrows color
                },
                "& .MuiDataGrid-menuIconButton": {
                  color: "white", // Three-dot menu button color
                },
              }}
            />
          </div>

          {/* Add Dialog */}

          <Dialog
            fullWidth
            maxWidth="sm"
            open={addClick}
            onClose={() => setAddClick(false)}
          >
            <div className="flex flex-col gap-3 p-5">
              <h1 className="text-2xl font-semibold">Add a new tailor</h1>
              <input
                type="text"
                name="name"
                autoComplete="off"
                value={newTailor.name}
                onChange={(e) =>
                  setNewTailor((prev) => ({ ...prev, name: e.target.value }))
                }
                placeholder="Name"
                className="border px-3 py-2 rounded"
              />
              <input
                type="tel"
                name="contact"
                autoComplete="off"
                value={newTailor.contact}
                onChange={(e) =>
                  setNewTailor((prev) => ({ ...prev, contact: e.target.value }))
                }
                placeholder="Contact"
                className="border px-3 py-2 rounded"
              />
              <textarea
                name="address"
                autoComplete="off"
                value={newTailor.address}
                onChange={(e) =>
                  setNewTailor((prev) => ({ ...prev, address: e.target.value }))
                }
                placeholder="Address"
                rows={3}
                className="border px-3 py-2 rounded"
              />
              <div className="flex justify-end gap-3">
                <button
                  className="px-4 py-2 bg-secondary text-white rounded"
                  onClick={handleAdd}
                >
                  Submit
                </button>
                <button
                  className="px-4 py-2 border rounded"
                  onClick={() => {
                    setAddClick(false);
                    setNewTailor({ name: "", contact: "", address: "" });
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Dialog>

          {/* Edit Dialog */}

          <Dialog
            fullWidth
            maxWidth="sm"
            open={editClick}
            onClose={() => setEditClick(false)}
          >
            <div className="flex flex-col gap-3 p-5">
              <h1 className="text-2xl font-semibold">Edit Tailor</h1>
              <input
                type="text"
                name="name"
                autoComplete="off"
                value={selectedTailor?.name || ""}
                onChange={(e) =>
                  setSelectedTailor((prev) => ({
                    ...prev,
                    name: e.target.value,
                  }))
                }
                placeholder="Name"
                className="border px-3 py-2 rounded"
              />
              <input
                type="tel"
                name="contact"
                autoComplete="off"
                value={selectedTailor?.contact || ""}
                onChange={(e) =>
                  setSelectedTailor((prev) => ({
                    ...prev,
                    contact: e.target.value,
                  }))
                }
                placeholder="Contact"
                className="border px-3 py-2 rounded"
              />
              <textarea
                name="address"
                autoComplete="off"
                value={selectedTailor?.address || ""}
                onChange={(e) =>
                  setSelectedTailor((prev) => ({
                    ...prev,
                    address: e.target.value,
                  }))
                }
                placeholder="Address"
                rows={3}
                className="border px-3 py-2 rounded"
              />
              <div className="flex justify-end gap-3">
                <button
                  className="px-4 py-2 bg-secondary text-white rounded"
                  onClick={handleUpdate}
                >
                  Save
                </button>
                <button
                  className="px-4 py-2 border rounded"
                  onClick={() => setEditClick(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Dialog>

          {/* Delete Dialog */}

          <Dialog
            fullWidth
            maxWidth="xs"
            open={deleteClick}
            onClose={() => setDeleteClick(false)}
          >
            <div className="flex flex-col gap-3 p-5">
              <h1 className="text-2xl font-semibold">Delete Tailor</h1>
              <span>Are you sure you want to delete {selectedTailor}?</span>
              <div className="flex items-center gap-4 justify-end">
                <button className="font-medium" onClick={handleDelete}>
                  Delete
                </button>
                <button
                  className="font-medium"
                  onClick={() => setDeleteClick(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default Tailors;
