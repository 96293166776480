import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Login from "./pages/login";
import Tailors from "./pages/tailors";
import Layout from "./components/Layout";
import Dashboard from "./pages/dashboard";
import OrderDetail from "./pages/orderDetail";
import ScrollToTop from "./components/ScrollToTop";
import ProtectedRoute from "./components/ProtectedRoute";

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <ProtectedRoute
              element={
                <Layout>
                  <Dashboard />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/order/:id"
          element={
            <ProtectedRoute
              element={
                <Layout>
                  <OrderDetail />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/tailors"
          element={
            <ProtectedRoute
              element={
                <Layout>
                  <Tailors />
                </Layout>
              }
            />
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
