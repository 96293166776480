const SizeChart = [
  { measurement: "Shoulder", XS: 13.5, S: 14, M: 14.5, L: 15, XL: 16 },
  { measurement: "Sleeve Length", XS: 21, S: 21.5, M: 22, L: 22.5, XL: 23 },
  { measurement: "Sleeve Opening", XS: 6, S: 6.5, M: 7, L: 7.5, XL: 8 },
  { measurement: "Armhole", XS: 8.5, S: 9, M: 9.75, L: 11, XL: 11.75 },
  { measurement: "Bust/Chest", XS: 18, S: 19, M: 20, L: 22, XL: 23 },
  { measurement: "Shirt Length", XS: 40, S: 40, M: 41, L: 41, XL: 41 },
  { measurement: "Waist", XS: 17.5, S: 18.5, M: 20, L: 22, XL: 24 },
  { measurement: "Hip", XS: 19.5, S: 20.5, M: 22, L: 24, XL: 26 },
  { measurement: "Thigh", XS: 11.5, S: 12, M: 13, L: 13.5, XL: 14 },
  { measurement: "Bottom Opening", XS: 6, S: 6.5, M: 7, L: 7.5, XL: 8 },
  { measurement: "Bottom Length", XS: 37, S: 38, M: 39, L: 40, XL: 41 },
];

export default SizeChart;
