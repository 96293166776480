import axios from "axios";
import Cookies from "js-cookie";

const baseURL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
  baseURL: baseURL,
});

// ************** GET TOKEN FROM COOKIES ***************

api.interceptors.request.use(
  (config) => {
    const token = Cookies.get("adminAuthToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// ************** LOGIN ***************

export const login = async (credentials) => {
  try {
    const response = await api.post("/api/admin/login", credentials);
    const { token } = response.data;

    Cookies.set("adminAuthToken", token, { sameSite: "None", secure: true });

    return response.data;
  } catch (error) {
    throw error;
  }
};

// ************** GET ALL ORDERS ***************

export const getAllOrders = async (page, limit) => {
  try {
    const response = await api.get(
      `/api/order/list-with-pagination?page=${page}&limit=${limit}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// ************** GET ORDERS BY STATUS ***************

export const getOrdersByStatus = async () => {
  try {
    const response = await api.get(`/api/order/count-by-status`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// ************** UPDATE ORDER STATUS ***************

export const updateOrderStatus = async (userData) => {
  try {
    const response = await api.put(`/api/order/${userData.orderId}`, userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// ************** DELETE ORDER ***************

export const deleteOrder = async (id) => {
  try {
    const response = await api.delete(`/api/order/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// ************** GET NOTIFICATIONS ***************

export const getNotifications = async () => {
  try {
    const response = await api.get(`/api/notification/list`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// ************** IS READ NOTIFICATIONS ***************

export const isReadNtf = async (id, ntf) => {
  try {
    const response = await api.put(`/api/notification/update/${id}`, ntf);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// ************** CLEAR NOTIFICATIONS ***************

export const clearNotifications = async () => {
  try {
    const response = await api.delete("/api/notification/delete");
    return response.data;
  } catch (error) {
    throw error;
  }
};

// ************** GET TAILORS ***************

export const getTailors = async () => {
  try {
    const response = await api.get(`/api/tailor/list`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// ************** CREATE TAILOR ***************

export const createTailor = async (tailorData) => {
  try {
    const response = await api.post(`/api/tailor/create`, tailorData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// ************** UPDATE TAILOR ***************

export const updateTailor = async (tailorData) => {
  try {
    const response = await api.put(`/api/tailor/${tailorData._id}`, tailorData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// ************** DELETE TAILOR ***************

export const deleteTailor = async (id) => {
  try {
    const response = await api.delete(`/api/tailor/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
