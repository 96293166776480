import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { S3Client, DeleteObjectCommand } from "@aws-sdk/client-s3";

import SizeChart from "../components/SizeChart";
import { useOrders } from "../context/OrdersContext";
import { updateOrderStatus, deleteOrder } from "../api";

const OrderDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { fetchAllOrders } = useOrders();
  const { order, allOrders } = location.state || {};

  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // ************** OPEN DIALOGUE ***************

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  // ************** CLOSE DIALOGUE ***************

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  //************ DELETE ORDER IAMGES *********** */

  const s3Client = new S3Client({
    region: process.env.REACT_APP_AWS_S3_REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID || "",
      secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY || "",
    },
  });

  const deleteS3File = async (bucketName, key) => {
    try {
      if (typeof key !== "string") {
        key = key.toString();
      }

      const deleteParams = {
        Bucket: bucketName,
        Key: key.replace(/^https?:\/\/[^/]+\/(.+)$/, "$1"),
      };

      const command = new DeleteObjectCommand(deleteParams);
      await s3Client.send(command);
    } catch (error) {
      console.error("Error deleting file from S3:", error);
      throw new Error("Failed to delete file from S3");
    }
  };

  // ************** CANCEL ORDER ***************

  const handleCancelOrder = async (orderId, payment, refImgs) => {
    setLoading(true);

    try {
      const imagesToDelete = refImgs.flat();

      for (const refImg of imagesToDelete) {
        await deleteS3File(
          process.env.REACT_APP_AWS_S3_BUCKET_NAME || "",
          refImg
        );
      }
      await deleteS3File(
        process.env.REACT_APP_AWS_S3_BUCKET_NAME || "",
        payment
      );
      await deleteOrder(orderId);

      setLoading(false);
      setIsDialogOpen(false);
      navigate("/");
      fetchAllOrders();
    } catch (error) {
      setLoading(false);
      toast.error("Error deleting order!");
      console.error("Failed to delete order", error);
    }
  };

  // ************** STATUS CHANGE ***************

  const [statusMap, setStatusMap] = useState(
    allOrders?.reduce((acc, curr) => {
      acc[curr._id] = curr.status;
      return acc;
    }, {}) || {}
  );

  const handleStatusChange = async (orderId, newStatus) => {
    try {
      const userData = { orderId, status: newStatus };
      await updateOrderStatus(userData);

      setStatusMap((prev) => ({
        ...prev,
        [orderId]: newStatus,
      }));

      if (newStatus.toLowerCase() === "delivered") {
        const orderToUpdate = allOrders.find((order) => order._id === orderId);
        if (orderToUpdate) {
          const imagesToDelete = orderToUpdate.products.flatMap(
            (product) => product.design_images || []
          );

          // Delete all associated design images
          for (const refImg of imagesToDelete) {
            await deleteS3File(
              process.env.REACT_APP_AWS_S3_BUCKET_NAME || "",
              refImg
            );
          }

          // Delete payment evidence image if it exists
          if (orderToUpdate.payment_evidence) {
            await deleteS3File(
              process.env.REACT_APP_AWS_S3_BUCKET_NAME || "",
              orderToUpdate.payment_evidence
            );
          }
        }
      }

      toast.success("Status updated successfully!");
    } catch (error) {
      toast.error("Failed to update status.");
      console.error("Error updating order status:", error);
    }
  };

  // ************** PAYMENT METHOD MAPPING ***************

  const paymentMethodMapping = {
    bank_transfer: "Bank Transfer",
    payoneer: "Payoneer",
  };

  // ************** IMAGE VIEW MODAL ***************

  const ImageModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center mt-16">
      <img
        src={selectedImage}
        alt="Selected Design"
        className="max-w-full max-h-[70vh] object-contain"
      />
      <button
        onClick={() => setSelectedImage(null)}
        className="absolute top-3 right-3 text-black font-bold bg-white rounded-full w-10 h-10"
      >
        X
      </button>
    </div>
  );

  return (
    <>
      <ToastContainer autoClose={2000} position="top-right" />
      <div className="grid lg:grid-cols-3 grid-cols-1 gap-4">
        <div className="lg:col-span-2 flex flex-col gap-3">
          <div
            className="flex md:flex-row flex-col md:items-center md:justify-between 
            bg-white border rounded-lg p-4 md:gap-0 gap-3"
          >
            <h1 className="font-medium md:text-2xl text-xl">Order Detail</h1>

            <button
              onClick={openDialog}
              className="text-white font-medium rounded md:w-fit w-full px-3 py-2 bg-red-600"
            >
              Cancel Order
            </button>

            {isDialogOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                <div className="bg-white p-6 rounded shadow-lg lg:w-1/3 md:w-2/3 w-3/3">
                  <h2 className="text-lg font-medium mb-3">
                    Confirm Cancellation
                  </h2>
                  <p>Are you sure you want to cancel the order?</p>

                  <div className="mt-4 flex justify-end">
                    <button
                      className="bg-gray-400 text-white rounded px-4 py-2 mr-2"
                      onClick={closeDialog}
                    >
                      No
                    </button>

                    <button
                      disabled={loading}
                      onClick={() =>
                        handleCancelOrder(
                          order._id,
                          order.payment_evidence,
                          order.products.flatMap((img) => img.design_images)
                        )
                      }
                      className={`text-white font-medium rounded w-fit px-3 py-2
                      ${
                        loading
                          ? "bg-secondary cursor-not-allowed"
                          : "bg-red-600"
                      } `}
                    >
                      <span>
                        {loading ? "Cancelling Order..." : "Yes, Cancel"}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="flex flex-col gap-3 bg-white border rounded-lg p-4">
            <span className="text-xl font-medium">Order History</span>
            <div className="grid md:grid-cols-2 grid-cols-1 md:gap-y-1 gap-y-2">
              <div className="flex md:flex-row flex-col md:items-center md:gap-2 gap-0">
                <h1 className="font-medium">Order ID:</h1>
                <span>{order._id}</span>
              </div>

              <div className="flex md:flex-row flex-col md:items-center md:gap-2 gap-0">
                <span className="font-medium">Order placed on:</span>
                <span>
                  {new Date(order.createdAt).toLocaleDateString("en-US", {
                    weekday: "short",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </span>
              </div>

              <div className="flex items-center gap-2">
                <span className="font-medium">Order Price:</span>
                <span>Rs {order.order_price}</span>
              </div>

              <div className="flex items-center gap-2">
                <span className="font-medium">Advance Paid:</span>

                {order.advance_payment ? (
                  <span>Rs {order.advance_payment}</span>
                ) : (
                  <span>Waiting for confirmation</span>
                )}
              </div>

              <div className="flex md:flex-row flex-col md:items-center md:gap-2 gap-0">
                <span className="font-medium">Advance Payment Method:</span>
                <span>{order.payment_mode}</span>
              </div>

              {order.advance_payment_date && (
                <div className="flex md:flex-row flex-col md:items-center md:gap-2 gap-0">
                  <span className="font-medium">Advance Payment Date:</span>
                  <span>
                    {new Date(order.advance_payment_date).toLocaleDateString(
                      "en-US",
                      {
                        weekday: "short",
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      }
                    )}
                  </span>
                </div>
              )}

              <div className="flex md:flex-row flex-col md:items-center md:gap-2 gap-0">
                <span className="font-medium">Order Status:</span>
                <select
                  value={statusMap[order._id]}
                  onClick={(e) => e.stopPropagation()}
                  className="h-9 border rounded-md cursor-pointer"
                  onChange={(e) =>
                    handleStatusChange(order._id, e.target.value)
                  }
                >
                  <option value="booked">Booked</option>
                  <option value="scheduled_for_pickup">
                    Scheduled for pickup
                  </option>
                  <option value="arrived_at_filato">Arrived at Filato</option>
                  <option value="in_process">In process</option>
                  <option value="finishing">Finishing</option>
                  <option value="out_for_delivery">Out for delivery</option>
                  <option value="delivered">Delivered</option>
                </select>
              </div>

              <div className="flex md:flex-row flex-col md:items-center md:gap-2 gap-0">
                <span className="font-medium">Additional Charges:</span>
                <span>
                  Rs {order.additional_charges ? order.additional_charges : 0}
                </span>
              </div>

              <div className="flex md:flex-row flex-col md:items-center md:gap-2 gap-0">
                <span className="font-medium">Amount Pending:</span>
                <span>
                  Rs{" "}
                  {Number(order.order_price) +
                    (order.additional_charges
                      ? Number(order.additional_charges)
                      : 0) -
                    (order.advance_payment ? Number(order.advance_payment) : 0)}
                </span>
              </div>

              {order.final_payment_paid && (
                <div className="flex md:flex-row flex-col md:items-center md:gap-2 gap-0">
                  <span className="font-medium">Final Payment Paid:</span>
                  <span>Rs {order.final_payment_paid}</span>
                </div>
              )}

              {order.final_payment_date && (
                <div className="flex md:flex-row flex-col md:items-center md:gap-2 gap-0">
                  <span className="font-medium">Final Payment Date:</span>
                  <span>
                    {new Date(order.final_payment_date).toLocaleDateString(
                      "en-US",
                      {
                        weekday: "short",
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      }
                    )}
                  </span>
                </div>
              )}

              {order.final_payment_method && (
                <div className="flex md:flex-row flex-col md:items-center md:gap-2 gap-0">
                  <span className="font-medium">Final Payment Account:</span>
                  <span>
                    {paymentMethodMapping[order.final_payment_method] ||
                      order.final_payment_method}
                  </span>
                </div>
              )}

              <div className="flex md:flex-row flex-col md:items-center md:gap-2 gap-0">
                <span className="font-medium">Remaining Balance:</span>
                Rs {order.remaining_balance ? order.remaining_balance : 0}
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-3 bg-white border rounded-lg p-4">
            <span className="text-xl font-medium">Order Tracking</span>

            <div className="grid md:grid-cols-2 grid-cols-1 md:gap-y-1 gap-y-2">
              {order.pickup_date && (
                <div className="flex md:flex-row flex-col md:items-center md:gap-2 gap-0">
                  <span className="font-medium">Pickup Date:</span>
                  <span>
                    {new Date(order.pickup_date).toLocaleDateString("en-US", {
                      weekday: "short",
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </span>
                </div>
              )}

              {order.pickup_tracking_id && (
                <div className="flex md:flex-row flex-col md:items-center md:gap-2 gap-0">
                  <span className="font-medium">Pickup Tracking ID:</span>
                  <span>{order.pickup_tracking_id}</span>
                </div>
              )}

              {order.receiving_date && (
                <div className="flex md:flex-row flex-col md:items-center md:gap-2 gap-0">
                  <span className="font-medium">Receiving Date:</span>
                  <span>
                    {new Date(order.receiving_date).toLocaleDateString(
                      "en-US",
                      {
                        weekday: "short",
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      }
                    )}
                  </span>
                </div>
              )}

              <div className="flex md:flex-row flex-col md:items-center md:gap-2 gap-0">
                <span className="font-medium">TCS Pickup Job:</span>
                <span
                  className={`font-medium ${
                    order.receiving_date ? "text-lime-600" : "text-orange-600"
                  }`}
                >
                  {order.receiving_date ? "completed" : "pending"}
                </span>
              </div>

              {order.dispatch_date && (
                <div className="flex md:flex-row flex-col md:items-center md:gap-2 gap-0">
                  <span className="font-medium">Dispatch Date:</span>
                  <span>
                    {new Date(order.dispatch_date).toLocaleDateString("en-US", {
                      weekday: "short",
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </span>
                </div>
              )}

              {order.delivery_tracking_id && (
                <div className="flex md:flex-row flex-col md:items-center md:gap-2 gap-0">
                  <span className="font-medium">Delivery Tracking ID:</span>
                  <span>{order.delivery_tracking_id}</span>
                </div>
              )}

              {order.delivered_date && (
                <div className="flex md:flex-row flex-col md:items-center md:gap-2 gap-0">
                  <span className="font-medium">Delivered Date:</span>
                  <span>
                    {new Date(order.delivered_date).toLocaleDateString(
                      "en-US",
                      {
                        weekday: "short",
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      }
                    )}
                  </span>
                </div>
              )}

              <div className="flex md:flex-row flex-col md:items-center md:gap-2 gap-0">
                <span className="font-medium">TCS delivery Job:</span>
                <span
                  className={`font-medium ${
                    order.delivered_date ? "text-lime-600" : "text-orange-600"
                  }`}
                >
                  {order.delivered_date ? "completed" : "pending"}
                </span>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-3 bg-white border rounded-lg p-4">
            <span className="text-xl font-medium">Tailor Details</span>

            <div className="grid md:grid-cols-2 grid-cols-1 md:gap-y-1 gap-y-2">
              <div className="flex md:flex-row flex-col md:items-center md:gap-2 gap-0">
                <span className="font-medium">Tailor Assigned:</span>
                <span>
                  {order.tailor?.name ? order.tailor.name : "Not assigned yet"}
                </span>
              </div>

              <div className="flex md:flex-row flex-col md:items-center md:gap-2 gap-0">
                <span className="font-medium">Tailor Outbound Date:</span>
                <span
                  className={`font-medium ${
                    order.tailor_outbound_date ? null : "text-orange-600"
                  }`}
                >
                  {order.tailor_outbound_date
                    ? new Date(order.tailor_outbound_date).toLocaleDateString(
                        "en-US",
                        {
                          weekday: "short",
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }
                      )
                    : "pending"}
                </span>
              </div>

              <div className="flex md:flex-row flex-col md:items-center md:gap-2 gap-0">
                <span className="font-medium">Tailor Inbound Date:</span>
                <span
                  className={`font-medium ${
                    order.tailor_inbound_date ? null : "text-orange-600"
                  }`}
                >
                  {order.tailor_inbound_date
                    ? new Date(order.tailor_inbound_date).toLocaleDateString(
                        "en-US",
                        {
                          weekday: "short",
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }
                      )
                    : "pending"}
                </span>
              </div>
            </div>
          </div>

          {order.products?.map((item, index) => {
            const selectedSize = item.size;
            const measurementsForSize = SizeChart.map((measurementObj) => ({
              measurement: measurementObj.measurement,
              value: measurementObj[selectedSize],
            }));

            return (
              <div
                key={index}
                className="flex flex-col gap-2 bg-white border rounded-lg p-4"
              >
                {item.service_type && (
                  <div className="flex md:flex-row flex-col md:items-center md:gap-2 gap-0">
                    <span className="font-medium min-w-fit">Buy for me:</span>
                    <a
                      target="_blank"
                      href={item.service_type}
                      rel="noopener noreferrer"
                      className="text-blue-800 hover:text-red-500 truncate"
                    >
                      {item.service_type}
                    </a>
                  </div>
                )}

                <div className="flex flex-col space-y-1">
                  <span className="text-xl font-medium">Dress {index + 1}</span>

                  <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-y-1">
                    {item.dress_type && (
                      <div className="flex items-center gap-2">
                        <span className="font-medium">Dress Type:</span>
                        <span>{item.dress_type}</span>
                      </div>
                    )}

                    {item.dress_fit_type && (
                      <div className="flex items-center gap-2">
                        <span className="font-medium">Dress Fit:</span>
                        <span>{item.dress_fit_type}</span>
                      </div>
                    )}

                    {item.top_style && (
                      <div className="flex items-center gap-2">
                        <span className="font-medium">Top Style:</span>
                        <span>{item.top_style}</span>
                      </div>
                    )}

                    {item.bottom_style && (
                      <div className="flex items-center gap-2">
                        <span className="font-medium">Bottom Style:</span>
                        <span>{item.bottom_style}</span>
                      </div>
                    )}

                    {item.neck_style && (
                      <div className="flex items-center gap-2">
                        <span className="font-medium">Neck Style:</span>
                        <span>{item.neck_style}</span>
                      </div>
                    )}

                    {item.sleeves_style && (
                      <div className="flex items-center gap-2">
                        <span className="font-medium">Sleeves Style:</span>
                        <span>{item.sleeves_style}</span>
                      </div>
                    )}
                  </div>
                </div>

                {item.sizeId ? (
                  <div className="flex flex-col space-y-1">
                    <span className="font-medium">Custom Size (Inches)</span>

                    <div className="grid lg:grid-cols-6 md:grid-cols-4 grid-cols-2 -m-2 mt-1 xl:text-base text-sm">
                      <span className="font-medium p-2 border bg-light rounded-tl-md">
                        Top Length
                      </span>
                      <span className="p-2 border-y border-r">
                        {item.sizeId.top_length}
                      </span>
                      <span className="font-medium p-2 border-r border-y bg-light">
                        Neck
                      </span>
                      <span className="p-2 border-y border-r">
                        {item.sizeId.neck}
                      </span>
                      <span className="font-medium p-2 border-r border-y bg-light">
                        Shoulder
                      </span>
                      <span className="p-2 border-y border-r rounded-tr-md">
                        {item.sizeId.shoulder}
                      </span>
                      <span className="font-medium p-2 border-x border-b bg-light">
                        Sleeves
                      </span>
                      <span className="p-2 border-b border-r">
                        {item.sizeId.sleeves}
                      </span>
                      <span className="font-medium p-2 border-r border-b bg-light">
                        Arm Hole
                      </span>
                      <span className="p-2 border-b border-r">
                        {item.sizeId.arm_hole}
                      </span>
                      <span className="font-medium p-2 border-r border-b bg-light">
                        Bicep
                      </span>
                      <span className="p-2 border-b border-r">
                        {item.sizeId.bicep}
                      </span>
                      <span className="font-medium p-2 border-x border-b bg-light">
                        Wrist
                      </span>
                      <span className="p-2 border-b border-r">
                        {item.sizeId.wrist}
                      </span>
                      <span className="font-medium p-2 border-r border-b bg-light">
                        Chest
                      </span>
                      <span className="p-2 border-b border-r">
                        {item.sizeId.chest}
                      </span>
                      <span className="font-medium p-2 border-r border-b bg-light">
                        Back Width
                      </span>
                      <span className="p-2 border-b border-r">
                        {item.sizeId.back_chest}
                      </span>
                      <span className="font-medium p-2 border-x border-b bg-light rounded-bl-md">
                        Bottom Length
                      </span>
                      <span className="p-2 border-b border-r">
                        {item.sizeId.bottom_length}
                      </span>
                      <span className="font-medium p-2 border-r border-b bg-light">
                        Waist
                      </span>
                      <span className="p-2 border-b border-r">
                        {item.sizeId.waist}
                      </span>
                      <span className="font-medium p-2 border-r border-b bg-light">
                        Hip
                      </span>
                      <span className="p-2 border-r border-b rounded-br-md">
                        {item.sizeId.hip}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col space-y-1">
                    <span className="font-medium">Size - {item.size} </span>

                    <div className="overflow-x-auto rounded-md">
                      <table className="table-auto border-collapse border w-full text-sm rounded-md">
                        <thead>
                          <tr className="bg-light">
                            <th className="border px-4 py-2 text-left">
                              Measurement
                            </th>
                            <th className="border px-4 py-2 text-left">
                              Value (inches)
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {measurementsForSize.map(
                            ({ measurement, value }, idx) => (
                              <tr key={idx}>
                                <td className="border px-4 py-2">
                                  {measurement}
                                </td>
                                <td className="border px-4 py-2">{value}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                {item.message && (
                  <div className="flex flex-col">
                    <span className="font-medium">Additional Information:</span>
                    <span>{item.message}</span>
                  </div>
                )}

                {item.design_images?.length > 0 && (
                  <div className="flex flex-col gap-1">
                    <span className="font-medium">Reference Design:</span>
                    <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 items-center lg:gap-4 gap-2">
                      {item.design_images?.map((image, index) => (
                        <img
                          key={index}
                          src={image}
                          width={120}
                          height={120}
                          alt="dress design"
                          onClick={() => setSelectedImage(image)}
                          className="rounded w-full h-full max-h-40 object-cover"
                        />
                      ))}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>

        <div className="flex flex-col gap-3">
          <h1 className="font-medium md:text-2xl text-xl bg-white border rounded-lg py-5 px-4">
            Customer Details
          </h1>

          <div className="flex flex-col gap-1 bg-white border rounded-lg p-4">
            <h1 className="font-medium md:text-xl text-lg mb-2">
              Personal Detail
            </h1>

            <div className="flex items-center gap-2">
              <span className="font-medium">Name:</span>
              <span>{order.userId.name}</span>
            </div>

            <div className="flex items-center gap-2">
              <span className="font-medium">Gender:</span>
              <span>{order.userId.gender}</span>
            </div>

            <div className="flex items-center gap-2">
              <span className="font-medium">Email:</span>
              <span>{order.userId.email}</span>
            </div>

            <div className="flex items-center gap-2">
              <span className="font-medium">Phone number:</span>
              <span>+{order.userId.phone_number}</span>
            </div>
          </div>

          <div className="flex flex-col gap-2 bg-white border rounded-lg p-4">
            <h1 className="font-medium md:text-xl text-lg mb-2">
              Address Detail
            </h1>

            <div className="flex flex-col">
              <span className="font-medium">Pickup Address:</span>
              <span>{order.pickup_address ? order.pickup_address : "N/A"}</span>
            </div>

            <div className="flex flex-col">
              <span className="font-medium">Delivery Address:</span>
              <span>{order.delivery_address}</span>
            </div>
          </div>

          {order.payment_mode === "Bank Transfer" && (
            <div className="flex flex-col gap-2 bg-white border rounded-lg p-4">
              <span className="font-medium md:text-xl text-lg">
                Bank Transfered Payment:
              </span>
              <img
                alt="screenshoot"
                className="w-auto h-auto"
                src={order.payment_evidence}
                onClick={() => setSelectedImage(order.payment_evidence)}
              />
            </div>
          )}
        </div>
      </div>

      {selectedImage && <ImageModal />}
    </>
  );
};

export default OrderDetail;
