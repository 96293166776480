import React, { createContext, useContext, useState, useCallback } from "react";
import { getAllOrders, getOrdersByStatus } from "../api";

const OrdersContext = createContext();

export const OrdersProvider = ({ children }) => {
  const [allOrders, setAllOrders] = useState([]);
  const [ordersByStatus, setOrdersByStatus] = useState([]);
  const [loadingOrders, setLoadingOrders] = useState(true);

  // ************** FETCH ALL ORDERS ***************

  const fetchAllOrders = useCallback(async (currentPage) => {
    try {
      const data = await getAllOrders(currentPage + 1, 100);

      const sortedOrders = data.orders.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      setAllOrders(sortedOrders);
      console.log("orders", sortedOrders);
    } catch (error) {
      console.error("Error fetching orders:", error.message);
    } finally {
      setLoadingOrders(false);
    }
  }, []);

  // ************** FETCH ORDERS BY STATUS ***************

  const fetchOrdersByStatus = async () => {
    try {
      const response = await getOrdersByStatus();
      setOrdersByStatus(response?.data || {});
    } catch (error) {
      console.error("Error fetching orders:", error.message);
    }
  };

  return (
    <OrdersContext.Provider
      value={{
        allOrders,
        setAllOrders,
        ordersByStatus,
        loadingOrders,
        fetchAllOrders,
        fetchOrdersByStatus,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};

export const useOrders = () => useContext(OrdersContext);
